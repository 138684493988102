import React from 'react'

import classes from './ServiceBox.module.scss'
import MdArrowForward from 'react-ionicons/lib/MdArrowForward'

const ServiceBox = React.memo(props => {
  return (
    <div
      className={classes.serviceBox}
      onClick={props.onClick}
      onKeyDown={props.onClick}
    >
      <div className={classes.iconBox}>
        <div className={classes.icon}>
          <img src={props.icon} alt="icon" style={{ height: 34 }} />
        </div>
      </div>
      <div className={classes.content}>
        <div className={classes.title}>
          <h3>
            <span>{props.title}</span>
          </h3>
        </div>
        <div className={classes.description}>
          <p>{props.description}</p>
        </div>
      </div>
      <div role="button" className={classes.hoverBox}>
        <div className={classes.iconBox}>
          <div className={classes.icon}>
            <MdArrowForward fontSize="30px" style={{ fontWeight: 600 }} />
          </div>
        </div>
        <h3>{props.callTo}</h3>
      </div>
    </div>
  )
})

export default ServiceBox
