import React from 'react'
import classes from './PopUp.module.scss'
import MdClose from 'react-ionicons/lib/MdClose'

const PopUp = props => {
  return (
    <div
      role="button"
      className={classes.mainContainer}
      onClick={props.closePop}
      onKeyDown={props.closePop}
      tabIndex={0}
    >
      <div className={classes.popup}>
        <MdClose
          role="button"
          className={classes.closeIcon}
          fontSize={35}
          onClick={props.closePop}
          onKeyDown={props.closePop}
          tabIndex={0}
        />
        <div className={classes.iconBox}>
          <div className={classes.icon}>
            <img src={props.icon} alt="icon" style={{ height: 34 }} />
          </div>
        </div>
        <div className={classes.content}>
          <div className={classes.title}>
            <h3>{props.title}</h3>
          </div>
          <div className={classes.description}>
            <p>{props.description}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PopUp
