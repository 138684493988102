import React, { useState, useContext } from 'react'
import '../../../i18n'
import { graphql, useStaticQuery } from 'gatsby'

import { GlobalStateContext } from '../../../context/GlobalContextProvider'
import classes from './Services.module.scss'
import Grid from '@material-ui/core/Grid'
import ServiceBox from '../../../components/Containers/ServiceBox/ServiceBox'
import PopUp from '../../../components/UI/PopUp/PopUp'

import handshake from '../../../assets/images/pliki svg/handshake.svg'
import file from '../../../assets/images/pliki svg/file.svg'
import wallet from '../../../assets/images/pliki svg/wallet-filled-money-tool.svg'
import shield from '../../../assets/images/pliki svg/shield.svg'
import envelope from '../../../assets/images/pliki svg/black-back-closed-envelope-shape.svg'
import magnifier from '../../../assets/images/pliki svg/magnifier.svg'
import pin from '../../../assets/images/pliki svg/pin.svg'
import family from '../../../assets/images/pliki svg/family.svg'

const Services = props => {
  const [show1, setShow1] = useState(false)
  const [show2, setShow2] = useState(false)
  const [show3, setShow3] = useState(false)
  const [show4, setShow4] = useState(false)
  const [show5, setShow5] = useState(false)
  const [show6, setShow6] = useState(false)
  const [show7, setShow7] = useState(false)
  const [show8, setShow8] = useState(false)
  const [show9, setShow9] = useState(false)

  const state = useContext(GlobalStateContext)
  console.log(state.lang)
  const data = useStaticQuery(graphql`
    query {
      german: allDirectusServicesTranslation(
        filter: { language: { eq: "de" } }
      ) {
        edges {
          node {
            title
            short_details
            details
            language
          }
        }
      }
      polish: allDirectusServicesTranslation(
        filter: { language: { eq: "pl" } }
      ) {
        edges {
          node {
            title
            short_details
            details
            language
          }
        }
      }
      english: allDirectusServicesTranslation(
        filter: { language: { eq: "en" } }
      ) {
        edges {
          node {
            title
            short_details
            details
            language
          }
        }
      }
    }
  `)
  let edges = null
  if (state.lang === 'pl-PL') {
    edges = data.polish.edges
  } else if (state.lang === 'de-DE') {
    edges = data.german.edges
  } else {
    edges = data.english.edges
  }

  return (
    <div className={classes.servicesContainer}>
      <div className={classes.titleContainer}>
        <h1>{props.naszeUslugi}</h1>
      </div>
      <Grid container style={{ width: '90%', margin: 'auto' }}>
        <Grid item xs={12} sm={6} md={4}>
          <ServiceBox
            title={edges[0].node.title}
            description={edges[0].node.short_details}
            icon={handshake}
            onClick={() => setShow1(true)}
            callTo={props.callTo}
          />
          {show1 ? (
            <PopUp
              title={edges[0].node.title}
              description={edges[0].node.details}
              icon={handshake}
              closePop={() => setShow1(false)}
            ></PopUp>
          ) : null}
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <ServiceBox
            title={edges[1].node.title}
            description={edges[1].node.short_details}
            icon={file}
            onClick={() => setShow2(true)}
            callTo={props.callTo}
          />
          {show2 ? (
            <PopUp
              title={edges[1].node.title}
              description={edges[1].node.details}
              icon={file}
              closePop={() => setShow2(false)}
            ></PopUp>
          ) : null}
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <ServiceBox
            title={edges[2].node.title}
            description={edges[2].node.short_details}
            icon={family}
            onClick={() => setShow3(true)}
            callTo={props.callTo}
          />
          {show3 ? (
            <PopUp
              title={edges[2].node.title}
              description={edges[2].node.details}
              icon={family}
              closePop={() => setShow3(false)}
            ></PopUp>
          ) : null}
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <ServiceBox
            title={edges[3].node.title}
            description={edges[3].node.short_details}
            icon={wallet}
            onClick={() => setShow4(true)}
            callTo={props.callTo}
          />
          {show4 ? (
            <PopUp
              title={edges[3].node.title}
              description={edges[3].node.details}
              icon={wallet}
              closePop={() => setShow4(false)}
            ></PopUp>
          ) : null}
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <ServiceBox
            title={edges[4].node.title}
            description={edges[4].node.short_details}
            icon={shield}
            onClick={() => setShow5(true)}
            callTo={props.callTo}
          />
          {show5 ? (
            <PopUp
              title={edges[4].node.title}
              description={edges[4].node.details}
              icon={shield}
              closePop={() => setShow5(false)}
            ></PopUp>
          ) : null}
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <ServiceBox
            title={edges[5].node.title}
            description={edges[5].node.short_details}
            icon={envelope}
            onClick={() => setShow6(true)}
            callTo={props.callTo}
          />
          {show6 ? (
            <PopUp
              title={edges[5].node.title}
              description={edges[5].node.details}
              icon={envelope}
              closePop={() => setShow6(false)}
            ></PopUp>
          ) : null}
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <ServiceBox
            title={edges[6].node.title}
            description={edges[6].node.short_details}
            icon={magnifier}
            onClick={() => setShow7(true)}
            callTo={props.callTo}
          />
          {show7 ? (
            <PopUp
              title={edges[6].node.title}
              description={edges[6].node.details}
              icon={magnifier}
              closePop={() => setShow7(false)}
            ></PopUp>
          ) : null}
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <ServiceBox
            title={edges[7].node.title}
            description={edges[7].node.short_details}
            icon={pin}
            onClick={() => setShow8(true)}
            callTo={props.callTo}
          />
          {show8 ? (
            <PopUp
              title={edges[7].node.title}
              description={edges[7].node.details}
              icon={pin}
              closePop={() => setShow8(false)}
            ></PopUp>
          ) : null}
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <ServiceBox
            title={edges[8].node.title}
            description={edges[8].node.short_details}
            icon={pin}
            onClick={() => setShow9(true)}
            callTo={props.callTo}
          />
          {show9 ? (
            <PopUp
              title={edges[8].node.title}
              description={edges[8].node.details}
              icon={pin}
              closePop={() => setShow9(false)}
            ></PopUp>
          ) : null}
        </Grid>
      </Grid>
    </div>
  )
}

export default Services
