import React from 'react'

import classes from './AboutBox.module.scss'

const AboutBox = props => {
  return (
    <div
      className={classes.about}
      style={{
        backgroundImage: props.backgroundImage,
        backgroundColor: props.bgColor,
        ...props.style,
      }}
    >
      <div className={classes.content}>
        <div className={classes.title}>
          <h3>{props.title}</h3>
        </div>
        <div className={classes.description}>
          <p>{props.description}</p>
          {props.contact}
        </div>
        <div className={classes.button}>{props.button}</div>
      </div>
    </div>
  )
}

export default AboutBox
