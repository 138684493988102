import React, { useContext } from 'react'
import classes from './About.module.scss'
import { graphql, useStaticQuery, navigate } from 'gatsby'
import { GlobalStateContext } from '../../../context/GlobalContextProvider'

import { Grid } from '@material-ui/core'
import { Box } from '@material-ui/core'
import AboutBox from '../../../components/Containers/AboutBox/AboutBox'
import Button from '../../../components/UI/Button/Button'
import first from '../../../assets/images/Index/first/first.jpg'
import second from '../../../assets/images/Index/second/second.jpg'
import third from '../../../assets/images/Index/third/third.jpg'

const About = () => {
  const grey = '#e0dfdf'
  const white = '#ffffff'
  const darkBlue = '#072f62'

  const state = useContext(GlobalStateContext)
  const data = useStaticQuery(graphql`
    query {
      german: allDirectusMainpageTranslation(
        filter: { language: { eq: "de" } }
      ) {
        edges {
          node {
            ulica
            onasinfo
            onas
            naszbloginfo
            naszblog
            miasto
            telefon
            kontakt
            email
            napisz
            photo1
            photo2
            photo3
          }
        }
      }
      polish: allDirectusMainpageTranslation(
        filter: { language: { eq: "pl" } }
      ) {
        edges {
          node {
            ulica
            onasinfo
            onas
            naszbloginfo
            naszblog
            miasto
            telefon
            kontakt
            email
            napisz
            photo1
            photo2
            photo3
          }
        }
      }
      english: allDirectusMainpageTranslation(
        filter: { language: { eq: "en" } }
      ) {
        edges {
          node {
            ulica
            onasinfo
            onas
            naszbloginfo
            naszblog
            miasto
            telefon
            kontakt
            email
            napisz
            photo1
            photo2
            photo3
          }
        }
      }
    }
  `)
  let edges = null
  if (state.lang === 'pl-PL') {
    edges = data.polish.edges
  } else if (state.lang === 'de-DE') {
    edges = data.german.edges
  } else {
    edges = data.english.edges
  }

  let firstP
  let secondP
  let thirdP

  if (edges[0].node.photo1 !== 0) {
    firstP = `url('${edges[0].node.photo1}')`
  } else {
    firstP = `url('${first}')`
  }
  if (edges[0].node.photo2 !== 0) {
    secondP = `url('${edges[0].node.photo2}')`
  } else {
    secondP = `url('${second}')`
  }
  if (edges[0].node.photo3 !== 0) {
    thirdP = `url('${edges[0].node.photo3}')`
  } else {
    thirdP = `url('${third}')`
  }
  console.log(second)

  return (
    <div className={classes.aboutContainer}>
      <Grid container className={classes.gridContainer}>
        <Box clone order={{ xs: 1, sm: 1 }}>
          <Grid item xs={12} sm={6} lg={4}>
            <AboutBox
              title={edges[0].node.onas}
              description={edges[0].node.onasinfo}
              button={
                <Button onClick={() => navigate('/about')}>
                  {edges[0].node.onas}
                </Button>
              }
              bgColor={grey}
            />
          </Grid>
        </Box>
        <Box clone order={{ xs: 2, sm: 2 }}>
          <Grid item xs={12} sm={6} lg={4}>
            <AboutBox backgroundImage={firstP} />
          </Grid>
        </Box>
        <Box clone order={{ xs: 4, sm: 3, lg: 4 }}>
          <Grid item xs={12} sm={6} lg={4}>
            <AboutBox backgroundImage={secondP} />
          </Grid>
        </Box>
        <Box clone order={{ xs: 3, sm: 4, lg: 3 }}>
          <Grid item xs={12} sm={6} lg={4}>
            <AboutBox
              title={edges[0].node.naszblog}
              description={edges[0].node.naszbloginfo}
              button={
                <Button onClick={() => navigate('/blog')}>
                  {edges[0].node.napisz}
                </Button>
              }
              bgColor={white}
            />
          </Grid>
        </Box>
        <Box clone order={{ xs: 5 }}>
          <Grid item xs={12} sm={6} lg={4}>
            <AboutBox
              contact={
                <p>
                  {edges[0].node.ulica}
                  <br />
                  {edges[0].node.miasto}
                  <br />
                  {edges[0].node.telefon}
                  <br />
                  {edges[0].node.email}
                </p>
              }
              style={{ color: '#ffffff' }}
              title={edges[0].node.kontakt}
              button={
                <Button
                  style={{ color: 'black' }}
                  onClick={() => navigate('/contact')}
                >
                  {edges[0].node.kontakt}
                </Button>
              }
              bgColor={darkBlue}
            />
          </Grid>
        </Box>
        <Box clone order={{ xs: 6 }}>
          <Grid item xs={12} sm={6} lg={4}>
            <AboutBox backgroundImage={thirdP} />
          </Grid>
        </Box>
      </Grid>
    </div>
  )
}

export default About
