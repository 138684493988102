import React, { useContext } from 'react'
import '../../i18n'
import { graphql, useStaticQuery } from 'gatsby'
import { GlobalStateContext } from '../../context/GlobalContextProvider'
// import classes from './Index.module.scss'
import Layout from '../../hoc/layout/layout'
import SEO from '../../hoc/seo'
import Header from '../../components/Header/Header'
import Services from './Services/Services'
import About from './About/About'

const Index = props => {
  const state = useContext(GlobalStateContext)
  const data = useStaticQuery(graphql`
    query {
      german: allDirectusMainpageTranslation(
        filter: { language: { eq: "de" } }
      ) {
        edges {
          node {
            title3
            title2
            title1
            naszeuslugi
            czytaj
            main
          }
        }
      }
      polish: allDirectusMainpageTranslation(
        filter: { language: { eq: "pl" } }
      ) {
        edges {
          node {
            title3
            title2
            title1
            naszeuslugi
            czytaj
            main
          }
        }
      }
      english: allDirectusMainpageTranslation(
        filter: { language: { eq: "en" } }
      ) {
        edges {
          node {
            title3
            title2
            title1
            naszeuslugi
            main
            czytaj
          }
        }
      }
    }
  `)
  let edges = null
  if (state.lang === 'pl-PL') {
    edges = data.polish.edges
  } else if (state.lang === 'de-DE') {
    edges = data.german.edges
  } else {
    edges = data.english.edges
  }
  return (
    <Layout>
      <SEO title={edges[0].node.main} />
      <Header
        content1={edges[0].node.title1}
        content2={edges[0].node.title2}
        content3={edges[0].node.title3}
      />
      <Services
        callTo={edges[0].node.czytaj}
        naszeUslugi={edges[0].node.naszeuslugi}
      />
      <About />
    </Layout>
  )
}

export default Index
