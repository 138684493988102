import React from 'react'
import classes from './Header.module.scss'
import Grid from '@material-ui/core/Grid'
import { animateScroll as scroll } from 'react-scroll'
import background from '../../assets/images/Headers/HeaderIndex/headerIndex.jpg'

import MdArrowDown from 'react-ionicons/lib/MdArrowDown'

const Header = props => {
  return (
    <div className={classes.header} style={props.style}>
      <Grid
        container
        className={classes.mainContainer}
        style={{
          margin: 0,
          backgroundImage: `url(${background})`,
        }}
      >
        <Grid item xs={12} className={classes.halfHeader}>
          <div className={classes.contentHeader_first}>
            <div className={classes.contentBox}>
              <h1>{props.content1}</h1>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} className={classes.halfHeader}>
          <div className={classes.contentHeader_second}>
            <div
              role="button"
              className={classes.arrowIcon}
              onClick={() => scroll.scrollTo(600)}
              onKeyDown={() => scroll.scrollTo(600)}
              tabIndex={0}
            >
              <MdArrowDown />
            </div>
            <div className={classes.contentBox}>
              <h1>{props.content2}</h1>
              {props.content3 ? (
                <h1 className={classes.hideMobile}>{props.content3}</h1>
              ) : null}
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default Header
